import React from 'react'
import Link from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"

const StudentsAskNewspapersToMakeTheirVoicesHeard = () => (

  <Layout>
    <SEO title="STUDENTS IN SUPPORT OF A FREE PRESS ASK NEWSPAPERS TO MAKE THEIR VOICES HEARD!" />
    
    <h1>STUDENTS IN SUPPORT OF A FREE PRESS ASK NEWSPAPERS TO MAKE THEIR VOICES HEARD!</h1>

    <p>Inspired by the Boston Globe’s #FreePress editorial response to President Trump’s assault on the news media, we invite newspapers to provide a forum for students nationwide, grades six through university, as they submit essays  on “why a free press matters in a democracy.” These new voices are smart, impressively informed, and tech savvy and will open up dialogue with influencers beyond themselves - their classmates, teachers, and parents - extending and enriching our national dialogue about the importance of the First Amendment.</p>
    <p>We are asking newspapers across the country to lend their support to this national student essay competition - not because we are insensitive to the burden of your responsibilities and time - but because we want to connect the students with you in a very concrete way during this project. To think about your challenges and your courage in our polarized country as they submit their essays to your newspaper. Invited by his/her newspaper, each student will know that his opinion and his effort will be read and valued. </p>
    <p>By adding their voice to the national dialogue, we are not only exponentially expanding public support for press freedom - at a time when witnesses and documents are withheld from the impeachment trial of a president who has built his power on accusations of “fake news’ -  but developing future leaders, enlightened citizens, and faithful readers  committed to the level of independent journalism your newspaper represents.</p>
    <p>Our goal is to connect the student essayist with your newspaper as he/she writes about the importance of a free press, its challenges today, and its future so that the First Amendment is not an abstraction but a profession of dedicated and courageous truth seekers in an era of mistrust. And we need your newspaper’s participation in order to create the connection and make these voices heard. </p>
    <p>We ask that your newspaper invite its readers to submit essays on the topic of why a free press matters and select the best in each of three categories: grades six-eight; grades nine-twelve; and university/college and select a winning essayist in each category. Subsequent rounds of judging, semi-finalist and finalist, will be done by a national jury once we receive the essays each newspaper has selected in each of the three categories.</p>
    <p>Call-for-entries will begin early March with an April 24, 2020 deadline for submissions followed by six weeks for each newspaper to review the submissions and select its winner in each of the three categories. Your selections will then be sent to our headquarters where a jury will judge the winning essays from each participating newspaper. The Boston Globe Foundation will award $5,000 to each winner and the overall winner will receive a full four-year scholarship, current annual value of $38,000, to Westminster College in Salt Lake City.</p>
    <p>We’ll provide templates for essay guidelines, essays topics, launch announcements, call-for-entries, deadline reminders, winner announcements, etc. to minimize your time and effort. We shall, of course, proudly announce, at all stages of the competition, participating papers as our partners and would welcome your logos. We are available to answer questions and to provide other support materials in support of the competition.</p>

  </Layout>

)

export default StudentsAskNewspapersToMakeTheirVoicesHeard